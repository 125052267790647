import QueryStringHandler from './QueryStringHandler.js';

/**
 * Updates url based on repository data.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class UrlHandler {
    constructor(repository) {
        if (!repository) {
            console.warn('UrlHandler needs an repository data source.');
        } else {
            this.repository = repository;
        }

        this.serverSideRendered = false;

        // get querystring
        this.qsParams = QueryStringHandler.getParams(QueryStringHandler.getQueryString());
    }

    init() {
        // the first page is rendered by the server,
        // after first server side rendering update after init event
        // of the repository
        this.repository.addListener('init', (data) => {
            if (this.serverSideRendered) {
                this.updateUrl(data);
            }
            this.serverSideRendered = true;
        });

        this.repository.addListener('updated', (pagedata) => {
            this.updateUrl(pagedata);
        });
    }

    getQueryStringParams() {
        return this.qsParams;
    }

    updateUrl(data) {
        let obj = {};
        if ($.isArray(data.SelectedTags)) {
            obj = { Tags: data.SelectedTags, Page: data.Page };
        } else {
            obj = $.extend(data.SelectedTags, { Page: data.Page });
        }
        QueryStringHandler.updateUrl(QueryStringHandler.flattenParams(obj));
        // console.log(data.SelectedTags, data.Page);
    }
}

export default UrlHandler;
