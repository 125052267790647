/**
 * Pagination functionality.
 *
 * @module foundation/Pagination
 */
import 'jquery.easing';
import 'jquery.scrollto';
import UrlHandler from 'UrlHandler';

/**
 * Pagination functionality.
 *
 * @requires jquery.easing
 * @requires jquery.scrollto
 * @requires module:project/Common.UrlHandler
 * @memberof module:foundation/Pagination
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class Pagination {

    /**
     * Initializes repository, gathers querystring data and handles button events.
     * @param {DOMElement} element DOM element to upgrade.
     * @param {object} repository Repository object to get data from.
     * @param {nmuber} maxResults Max results shown on page.
     */
    constructor(element, repository, maxResults) {
        this.$element = $(element);

        if (!repository) {
            console.warn('Pagination needs an repository data source.');
        } else {
            this.repository = repository;
        }

        // querystring data
        this.qsParams = null;
        this.urlHandler = new UrlHandler(this.repository);
        this.urlHandler.init();
        this.qsParams = this.urlHandler.getQueryStringParams();

        this.maxResults = maxResults || 20;
        this.totalItems = 0;
        this.currentPage;
        this.totalPages;
        this.serverSideRendered = false;

        this.disabledClass = 'disabled';
        this.activeClass = 'active';

        this.buttons = {
            'PREV': this.$element.find('a.page-link--previous'),
            'NEXT': this.$element.find('a.page-link--next'),
            'PAGE': this.$element.find('a.page-link:not(.page-link.page-link--previous):not(.page-link.page-link--next)').clone(),
            'DOTS': this.$element.find('div.page-link').clone()
        };

    }

    /**
     * Set currentPage and add repository listeners.
     */
    init() {
        this.currentPage = 1;

        // update filter with querystring params
        if (this.qsParams && typeof this.qsParams.Page !== 'undefined') {
            this.currentPage = this.qsParams.Page;
        }

        // the first page is rendered by the server,
        // after first server side rendering update after init event
        // of the repository
        this.repository.addListener('init', (data) => {
            if (this.serverSideRendered) {
                this.currentPage = 1;
                this.repository.getItems(0, this.maxResults);
            } else {
                this.update(data);
            }
            this.serverSideRendered = true;
        });

        this.repository.addListener('updated', (data) => this.update(data));

    }

    /**
     * Updates pagination state.
     * @param {object} data Repository data to update pagination with.
     */
    update(data) {

        $('.page-item--tpl').detach();

        // get total items
        this.totalItems = data.TotalItems;

        this.totalPages = Math.ceil(this.totalItems / this.maxResults);

        // console.log(`[pagination] Total: ${this.totalPages}, Current: ${this.currentPage}, InEnd: ${this.totalPages - 5}`);

        // remove all previously generated elements
        this.$element.find('.js-page-item').remove();

        // previous button
        if (this.currentPage - 1 < 1) {
            this.buttons.PREV.parent('.page-item').addClass('disabled');
        } else {
            this.buttons.PREV.parent('.page-item').removeClass('disabled');
        }

        if (this.totalPages > 7) {

            if (this.currentPage < 5 && this.currentPage < this.totalPages - 4) {
                // render first five pages
                for (let i = 1; i <= 5; i++) {
                    const $el = this.createPageItem(i);
                    this.$element.find(`li:nth-child(${i})`).after($el);
                }

                // render dots and last page
                const $liDots = $('<li></li>').addClass('page-item js-page-item').addClass(this.disabledClass);
                const $btnDots = this.buttons.DOTS.clone();
                const $elDots = $liDots.append($btnDots);
                this.$element.find('li:nth-child(6)').after($elDots);

                const $el = this.createPageItem(this.totalPages);
                this.$element.find('li:nth-child(7)').after($el);

            } else if (this.currentPage > 5 && this.currentPage > this.totalPages - 4) {

                // render first page and dots
                const $el = this.createPageItem(1);
                this.$element.find('li:nth-child(1)').after($el);

                const $liDots = $('<li></li>').addClass('page-item js-page-item').addClass(this.disabledClass);
                const $btnDots = this.buttons.DOTS.clone();
                const $elDots = $liDots.append($btnDots);
                this.$element.find('li:nth-child(2)').after($elDots);

                // render last five pages
                let pos = 3;
                for (let i = this.totalPages - 4; i <= this.totalPages; i++) {
                    const $el = this.createPageItem(i);
                    this.$element.find(`li:nth-child(${pos})`).after($el);
                    pos++;
                }
            } else {

                // render first page and dots
                const $el = this.createPageItem(1);
                this.$element.find('li:nth-child(1)').after($el);

                const $liFirstDots = $('<li></li>').addClass('page-item js-page-item').addClass(this.disabledClass);
                const $btnFirstDots = this.buttons.DOTS.clone();
                const $elFirstDots = $liFirstDots.append($btnFirstDots);
                this.$element.find('li:nth-child(2)').after($elFirstDots);

                // render current pages
                let pos = 3;
                for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
                    const $el = this.createPageItem(i);
                    this.$element.find(`li:nth-child(${pos})`).after($el);
                    pos++;
                }

                // render dots and last page
                const $liLastDots = $('<li></li>').addClass('page-item js-page-item').addClass(this.disabledClass);
                const $btnLastDots = this.buttons.DOTS.clone();
                const $elLastDots = $liLastDots.append($btnLastDots);
                this.$element.find('li:nth-child(6)').after($elLastDots);

                const $elLast = this.createPageItem(this.totalPages);
                this.$element.find('li:nth-child(7)').after($elLast);
            }

        } else {

            if (this.totalPages <= 7) {
                for (let i = 1; i <= this.totalPages; i++) {
                    const $el = this.createPageItem(i);
                    this.$element.find(`li:nth-child(${i})`).after($el);
                }
            }

        }

        // next button
        if (this.currentPage + 1 > this.totalPages) {
            this.buttons.NEXT.parent('.page-item').addClass('disabled');
        } else {
            this.buttons.NEXT.parent('.page-item').removeClass('disabled');
        }

        this.initButtons();
    }

    /**
     * Creates pagination page element.
     * @param {number} number
     */
    createPageItem(number) {
        const $li = $('<li></li>').addClass('page-item js-page-item');
        const $btn = this.buttons.PAGE.clone().text(number).attr('data-page', number);

        // highlight current page
        if (number === this.currentPage) {
            $li.addClass('active');
        }

        const $el = $li.append($btn);
        return $el;
    }

    /**
     * Initialize button behaviour.
     */
    initButtons() {
        this.buttons.PREV.off('click.pagination').on('click.pagination', this.handleButtonClick.bind(this));
        this.buttons.NEXT.off('click.pagination').on('click.pagination', this.handleButtonClick.bind(this));
        this.buttons.ITEMS = this.$element.find('a.page-link:not(.page-link.page-link--previous):not(.page-link.page-link--next)').on('click.pagination', this.handleButtonClick.bind(this));

        // update valtagmanager
        if (window.digitalData) { window.digitalData.update(); }
    }

    /**
     * Button functionality.
     * @param {event} e Event triggered by click on button.
     */
    handleButtonClick(e) {
        let $btn = $(e.currentTarget);
        if (!$btn.parent('.page-item').hasClass('active') && !$btn.parent('.page-item').hasClass('disabled')) {
            e.preventDefault();

            // scroll to top of list
            let offset = (0 - parseInt($(document.body).css('padding-top'))) - 50;
            $.scrollTo(this.$element.parents('.container').find('.itemlist'), 250, { axis: 'y', offset: { top: offset }, easing: 'easeInOutQuad', onAfter: () => {

                // get current page
                let page = this.currentPage;
                if (typeof $btn.data('page') === 'string') {
                    if ($btn.data('page') === 'prev') {
                        page = this.currentPage - 1;
                    } else {
                        page = this.currentPage + 1;
                    }
                } else {
                    page = $btn.data('page');
                }

                // convert page to startindex
                let start = (page-1)*this.maxResults;
                this.currentPage = page;

                // get page from repository
                this.repository.getItems(start, this.maxResults);
            }});

        }
    }
}

export default Pagination;