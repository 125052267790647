import EventEmitter from 'EventEmitter';
import AjaxHandler from 'AjaxHandler';
import QueryStringHandler from 'QueryStringHandler';

/**
 * Repository
 * Data manipulation functionality for news/media items.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class Repository extends EventEmitter {

    constructor(options) {
        super();

        // defaults
        this.options = {
            // base url of api
            apiBase: '/',
            // item guid used in the api
            guid: 'none',
            // language used in the api
            lang: 'en'
        };

        // update options
        this.options = $.extend(this.options, options);

        // init ajax helper
        this.ajax = new AjaxHandler({
            type: 'POST'
        });

        this.qsParams = QueryStringHandler.getParams(QueryStringHandler.getQueryString());

        // data to send with api calls
        this.data = {
            Item: this.options.itemGuid,
            Tags: [],
            SelectedTags: []
        };

        if (this.qsParams && typeof this.qsParams.Tags !== 'undefined') {
            this.data.Tags = this.qsParams.Tags;
            this.data.SelectedTags = this.qsParams.Tags;
        }
    }

    init() {
        // fill repository
        this.getAllItems(this.data);
    }

    clearFilters() {
        this.getAllItems({
            Item: this.options.itemGuid,
            Tags: [],
            SelectedTags: []
        }, true);
    }

    /**
     * Fetches items subset from api
     * @param {number} start
     * @param {number} [count] Options amount of items to retrieve. If left out, get items from start to last index.
     */
    getItems(start, count = this.data.TotalItems) {

        this.emit('beforeUpdate');

        let response = {
            TotalItems: this.data.TotalItems,
            Items: [],
            Page: (Math.ceil(start / count)) + 1,
            SelectedTags: this.data.SelectedTags
        };

        // add current page to data
        this.data = $.extend(this.data, { Page: response.Page });

        if (this.data.Items) {
            let promises = [];
            for (let i = 0; i < count; i++) {
                const promise = new Promise((resolve) => {
                    if (typeof this.data.Items[start + i] !== 'undefined') {
                        response.Items.push(this.data.Items[start + i]);
                    }
                    resolve();
                });
                promises.push(promise);
            }

            Promise.all(promises).then(() => {
                this.emit('updated', response, this.data);
            });
        } else {
            response.Success = this.data.Success;
            response.Reason = this.data.Reason;
            this.emit('updated', response, this.data);
        }
    }

    /**
     * Fetches all available items
     * @param {object} data Data to extend ajax request with.
     */
    getAllItems(data, reset) {
        this.emit('beforeInit');

        // extend data with guid
        //data.Item = this.data.Item;
        if (typeof reset !== 'undefined' && reset === true) {
            this.data = data;
        } else {
            this.data = $.extend(this.data, data);
        }

        this.data.Page = 1;
        delete this.data.Items;

        this.ajax.request({
            url: '/' + this.options.lang + '/' + this.options.apiBase,
            data: JSON.stringify(this.data)
        }).then((response) => {

            // store items
            this.data = $.extend(this.data, response);

            this.emit('init', this.data);

        }).catch((error) => {
            console.error(error);
        });
    }

    /**
     * Downloads zip file from all filtered results
     */
    downloadAll(e) {
        e.preventDefault;

        // API endpoint
        const apiDownloadall = 'api/feature/specsheet/download-all';

        // Filter values object
        const filterValues = this.data;

        // copy value from nested object
        if (filterValues.SelectedTags.Page) {
            filterValues.Page = filterValues.SelectedTags.Page;
        }

        // Remove unneeded properties
        delete filterValues.SelectedTags;
        delete filterValues.Tags;
        delete filterValues.Filters;
        delete filterValues.Items;
        delete filterValues.TotalItems;
        delete filterValues.Success;
        delete filterValues.Reason;

        // querystringify object values to querystring
        let queryString = Object.keys(filterValues).map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(filterValues[k]);
        }).join('&');

        if (queryString !== '') {
            location.reload();
            window.open('/' + apiDownloadall + '?' + queryString);
        }
    }
}

export default Repository;