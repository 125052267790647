/**
 * All functionality needed in News related components.
 *
 * @module feature/News
 */
import Repository from 'Repository';
import Filter from '../../../../../../Foundation/Tags/code/src/scripts/tags/TagFilter.js';
import Pagination from '../../../../../../Foundation/Pagination/code/src/scripts/pagination/Pagination.js';
import ResponsiveBackground from 'ResponsiveBackground';
import MasonryBase from 'MasonryBase';

/**
 * NewsArchive filter, pagination and masonry functionality.
 *
 * @requires module:project/Common.Repository
 * @requires module:project/Common.ResponsiveBackground
 * @requires module:project/Common.MasonryBase
 * @requires module:foundation/Tags.TagFilter
 * @requires module:foundation/Pagination.Pagination
 * @memberof module:feature/News
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class NewsArchive {
    /**
     * Upgrades DOM element, adds filter, pagination and repository functionality.
     * @param {DOMElement} element DOM element to upgrade.
     */
    constructor(element) {
        this.$element = $(element);
        this.itemGuid = this.$element.attr('data-guid');
        this.lang = this.$element.attr('data-lang');
        this.maxResults = this.$element.attr('data-items-per-page');

        this.repository = new Repository({
            apiBase: 'api/feature/news/getnews',
            itemGuid: this.itemGuid,
            lang: this.lang,
        });
        this.filter = new Filter(this.$element.find('.js-tag-filter'), this.repository).init();
        this.pagination = new Pagination(this.$element.find('.js-pagination'), this.repository, this.maxResults).init();

        this.msnry = null;
        this.$loader = this.$element.find('.loader').hide();

        this.$itemList = this.$element.find('.itemlist');
        this.itemTemplate = null;

        this.repository.init();
    }

    /**
     * Initializes masonry, builds card template and add listeners to repository.
     */
    init() {
        // init masonry
        this.msnry = new MasonryBase(this.$element);
        this.msnry.init();

        // get card template
        this.itemTemplate = this.buildTemplate(
            this.$itemList.find('.card--news:not(.card--news-highlight)').first().parent().clone()
        );

        // repository listeners
        this.repository.addListener('beforeInit', () => {
            this.$loader.fadeIn(200);
        });
        this.repository.addListener('beforeUpdate', () => {
            this.$loader.fadeIn(200);
        });
        this.repository.addListener('updated', (pagedata) => {
            this.updateList(pagedata);
        });
    }

    /**
     * Updates list with new items.
     * @param {object} data Json object or resultset to render in list.
     */
    updateList(data) {
        this.$loader.fadeIn(200, () => {
            if (data.Success === false) {
                this.$itemList.html(`<div class="alert alert-danger" role="alert">${data.Reason}</div>`);

                // hide loader
                this.$loader.fadeOut(200);
            } else {
                let htmlArray = ['<div class="col-1 grid-sizer"></div>'];
                let promises = [];

                // render all items
                for (let item of data.Items) {
                    let promise = new Promise((resolve) => {
                        this.renderItem(item).then((renderedItem) => {
                            htmlArray.push(renderedItem);
                            resolve();
                        });
                    });
                    promises.push(promise);
                }

                // update list when all items rendered
                Promise.all(promises).then(() => {
                    // update DOM
                    this.$itemList.html(htmlArray.join(''));

                    // responsive backgrounds
                    this.$itemList.find('.js-responsive-background').each((index, el) => {
                        new ResponsiveBackground($(el)).init();
                    });

                    // re-init masonry
                    // this.msnry = new MasonryBase(this.$element);
                    this.msnry.reload();

                    // update valtagmanager
                    if (window.digitalData) {
                        window.digitalData.update();
                    }

                    // hide loader
                    this.$loader.fadeOut(200);
                });
            }
        });
    }

    /**
     * Finds all variable elements and replaces it with tags
     * so we don't need find queries every time we render a card.
     * @param {jQueryDomElement}  $el Card to create template of.
     * @returns {string} HTML string of card.
     */
    buildTemplate($el) {
        const $wrapper = $('<div></div>').append($el);
        $wrapper.find('div').first().attr('class', '%%CLASSNAME%%').prop('style', false);
        $wrapper.find('.card--news').attr('class', '%%CARDCLASS%%');
        $wrapper.find('a').attr('href', '%%ITEMURL%%');
        $wrapper.find('.card__image').prop('style', false);
        $wrapper.find('.img-fluid').attr('src', '');
        $wrapper.find('.card-date').html('%%DATE%% - %%LOCATION%%');
        $wrapper.find('.card-title').html('%%TITLE%%');
        return $wrapper.html();
    }

    /**
     * Renders item to template.
     * @param {object} item Json object of item to render.
     */
    renderItem(item) {
        return new Promise((resolve) => {
            let html = this.itemTemplate;

            if (item.IsHighlighted === true) {
                html = html.replace(/%%CLASSNAME%%/g, 'col-md-12 col-lg-8 itemlist__item');
                html = html.replace(/%%CARDCLASS%%/g, 'card card--news card--news-highlight');
            } else {
                html = html.replace(/%%CLASSNAME%%/g, 'col-md-6 col-lg-4 itemlist__item');
                html = html.replace(/%%CARDCLASS%%/g, 'card card--news');
            }

            html = html.replace(/%%ITEMURL%%/g, item.Url);
            html = html.replace(/%%TITLE%%/g, item.Title);
            html = html.replace(/%%LOCATION%%/g, item.Location);
            html = html.replace(/%%DATE%%/g, item.Date);
            html = html.replace(/%%IMAGEURL%%/g, item.ImageUrl);
            // html = html.replace(/<img src="" class="img-fluid/g, `<img src="${item.ImageUrl}" class="img-fluid` || '');
            html = html.replace(/src=""/g, `src="${item.ImageUrl}"` || '');
            resolve(html);
        });
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: NewsArchive,
    classAsString: 'NewsArchive',
    cssClass: 'js-news-archive',
});

export default NewsArchive;
