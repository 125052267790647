/**
 * Tags functionality.
 *
 * @module foundation/Tags
 */
import 'bootstrap/js/dist/collapse.js';
import lodash from 'lodash';
import UrlHandler from 'UrlHandler';

/**
 * Tags Filter component functionality.
 *
 * @requires bootstrap/collapse
 * @requires lodash
 * @requires perfect-scrollbar
 * @requires module:project/Common.UrlHandler
 * @memberof module:foundation/Tags
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class TagFilter {
    /**
     * Upgrades DOM element, gathers querystring data and initializes collapses (for mobile) and filters.
     * @param {DOMElement} element DOM element to upgrade.
     * @param {object} [repository] Repository to get data from.
     */
    constructor(element, repository = null) {
        this.$element = $(element);

        if (!repository) {
            console.warn('The Tag filter needs an repository data source.');
        } else {
            this.repository = repository;
        }

        // querystring data
        this.qsParams = null;
        this.urlHandler = new UrlHandler(this.repository);
        this.urlHandler.init();
        this.qsParams = this.urlHandler.getQueryStringParams();

        // mobile collapsible
        this.isMobileCollapsible = false;
        this.$collapsibleMobile = this.$element.find('.collapse--mobile');
        this.$collapsibleMobileTrigger = this.$element.find('.mobile-collapse-toggle');

        // filter elements
        this.filters = this.getFilters();

        // cookie
        //this.cookie = Cookies.get();

        this.selectedTags = [];
    }

    /**
     * Sets up listeners on the repository, updates filter on querystring and handles resize event.
     */
    init() {
        // inits
        this.initMobileCollapse();
        this.initFilters();

        // repository listener
        this.repository.addListener('init', (data) => {
            this.update(data);
        });

        // update filter with querystring params
        if (this.qsParams && typeof this.qsParams.Tags !== 'undefined') {
            this.update({ SelectedTags: this.qsParams.Tags });
        }

        // events
        $(window).on(
            'resize',
            lodash.debounce(() => {
                this.initMobileCollapse();
            }, 200)
        );
    }

    /**
     * Updates filter state.
     * @param {object} data Repository data.
     */
    update(data) {
        const $tags = this.$element.find('input[type=checkbox]');

        // disable all tags first
        $tags.prop('disabled', true);
        $tags.parent().find('.badge').text(0);

        // set enabled tags
        if (typeof data.Tags !== 'undefined') {
            let tagsToEnable = data.Tags;
            for (let tag of tagsToEnable) {
                const $input = this.$element.find('input[value="' + tag.Value + '"]');
                if ($input.length > 0) {
                    $input.prop('disabled', false);
                    $input.parent('div').find('.badge').text(tag.Count);
                }
            }
        }

        // set checked tags
        if (typeof data.SelectedTags !== 'undefined') {
            // nasty(!!) fix
            if (typeof data.SelectedTags === 'string') {
                if (data.SelectedTags.split(',').length > 0) {
                    data.SelectedTags = data.SelectedTags.split(',');
                } else {
                    data.SelectedTags = [data.SelectedTags];
                }
            }

            let tagsToSelect = data.SelectedTags;
            for (let i = 0; i < tagsToSelect.length; i++) {
                const $input = this.$element.find('input[value="' + tagsToSelect[i] + '"]');
                if ($input.length > 0) {
                    $input.prop('checked', true);

                    // open parent collapse
                    $input.parents('.collapse').addClass('show');
                    // activate trigger
                    $input.parents('li.filter').find('.collapse-toggle').addClass('active');
                }
            }
        }

        // on updating tags, get all current selected
        $tags.off('click.tagfilter').on('click.tagfilter', () => {
            this.selectedTags = [];
            for (let tag of this.$element.find('input[type=checkbox]:checked')) {
                this.selectedTags.push($(tag).val());
            }

            // update repository
            this.repository.getAllItems({
                Tags: this.selectedTags.join(','),
                SelectedTags: this.selectedTags.join(','),
            });
        });
    }

    /**
     * Initializes mobile collapse functionality of the filter.
     */
    initMobileCollapse() {
        if (viewport.is('<=sm')) {
            this.$collapsibleMobile.collapse('hide');
            this.$collapsibleMobileTrigger.off('click.tagfilter').on('click.tagfilter', () => {
                this.$collapsibleMobile.collapse('toggle');
            });
        } else {
            this.$collapsibleMobileTrigger.off('click.tagfilter');
            this.$collapsibleMobile.collapse('dispose').removeClass('collapsing').addClass('show');
        }
    }

    /**
     * Initializes all filter collapsibles.
     */
    initFilters() {
        for (let filter of this.filters) {
            filter.$collapseToggle.on('click', (e) => {
                const $btn = $(e.currentTarget);
                if ($btn.hasClass('active')) {
                    $btn.removeClass('active');
                } else {
                    $btn.addClass('active');
                }
                filter.$collapsible.collapse('toggle');
            });
        }
    }

    /**
     * Get all filter elements and their collapsibles and triggers.
     * @returns {array} Array of objects with element, trigger and collapsible.
     */
    getFilters() {
        let filters = [];
        for (const filter of this.$element.find('.filter')) {
            filters.push({
                $el: $(filter),
                $collapseToggle: $(filter).find('.collapse-toggle'),
                $collapsible: $(filter).find('.collapse'),
            });
        }
        return filters;
    }
}

export default TagFilter;
